import {Injectable} from "@angular/core";
import {IResourceMethodObservableStrict, ResourceAction, ResourceParams, ResourceRequestBodyType, ResourceRequestMethod} from "@ngx-resource/core";
import {ApiResource} from "../../api-resource";
import {GroupedSubjectsByNameData, SubjectData, PagedData, LabelData} from "../data"

/**
 * This resource class was generated from Controller com.uniwunder.talentagent.main.controllers.public.PublicSubjectController
 */

@Injectable()
@ResourceParams({})
export class PublicSubjectResource extends ApiResource {

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/talentProfile/studies/academicTitles',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getAcademicTitles: IResourceMethodObservableStrict<null, null, null, string[]> | undefined
    getAcademicTitles(): Promise<string[]> {
        if (!this._getAcademicTitles) throw new Error("resource has not been properly initialized")
        return this._getAcademicTitles(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subjects/cherry',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMostFrequentlyCherrySubjects: IResourceMethodObservableStrict<null, null, null, LabelData[]> | undefined
    getMostFrequentlyCherrySubjects(): Promise<LabelData[]> {
        if (!this._getMostFrequentlyCherrySubjects) throw new Error("resource has not been properly initialized")
        return this._getMostFrequentlyCherrySubjects(null, null, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subjects/frequently/represented',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getMostFrequentlyRepresentedSubjects: IResourceMethodObservableStrict<null, {forcedIds: number[]}, null, LabelData[]> | undefined
    getMostFrequentlyRepresentedSubjects(requestParams: {forcedIds: number[]}): Promise<LabelData[]> {
        if (!this._getMostFrequentlyRepresentedSubjects) throw new Error("resource has not been properly initialized")
        return this._getMostFrequentlyRepresentedSubjects(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subject/{id}',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleSubjectById: IResourceMethodObservableStrict<null, null, {id: number}, LabelData> | undefined
    getSingleSubjectById(id: number): Promise<LabelData> {
        if (!this._getSingleSubjectById) throw new Error("resource has not been properly initialized")
        return this._getSingleSubjectById(null, null, {id: id}).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subject/byName',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSingleSubjectByName: IResourceMethodObservableStrict<null, {input: string}, null, GroupedSubjectsByNameData> | undefined
    getSingleSubjectByName(requestParams: {input: string}): Promise<GroupedSubjectsByNameData> {
        if (!this._getSingleSubjectByName) throw new Error("resource has not been properly initialized")
        return this._getSingleSubjectByName(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subjects',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjects: IResourceMethodObservableStrict<null, {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<SubjectData>> | undefined
    getSubjects(requestParams?: {q?: string, page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<SubjectData>> {
        if (!this._getSubjects) throw new Error("resource has not been properly initialized")
        return this._getSubjects(null, requestParams || {}, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subjects/groupedByDegree',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectsGroupedByDegree: IResourceMethodObservableStrict<null, {q: string}, null, GroupedSubjectsByNameData[]> | undefined
    getSubjectsGroupedByDegree(requestParams: {q: string}): Promise<GroupedSubjectsByNameData[]> {
        if (!this._getSubjectsGroupedByDegree) throw new Error("resource has not been properly initialized")
        return this._getSubjectsGroupedByDegree(null, requestParams, null).toPromise()
    }

    @ResourceAction({
        method: ResourceRequestMethod.Get,
        path: '/subjects/labels',
        endpoint: 'com.uniwunder.talentagent.main'
    })
    private _getSubjectsLabelData: IResourceMethodObservableStrict<null, {q?: string, degreeType?: ('Bachelor' | 'Master' | 'Magister' | 'Diploma' | 'Promotion' | 'StateExam' | 'Other'), page?: number, pageSize?: number, forcedIds?: number[]}, null, PagedData<LabelData>> | undefined
    getSubjectsLabelData(requestParams?: {q?: string, degreeType?: ('Bachelor' | 'Master' | 'Magister' | 'Diploma' | 'Promotion' | 'StateExam' | 'Other'), page?: number, pageSize?: number, forcedIds?: number[]}): Promise<PagedData<LabelData>> {
        if (!this._getSubjectsLabelData) throw new Error("resource has not been properly initialized")
        return this._getSubjectsLabelData(null, requestParams || {}, null).toPromise()
    }

}