<div style="background-color: white; min-width: 1030px; margin: 20px;" class="mat-elevation-z6">
    <div class="standard-container" fxLayout="column">
        <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">

            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                <mat-form-field class="input-field" appearance="outline">
                    <mat-label>Angebots-Status</mat-label>
                    <mat-select multiple="true" [(ngModel)]="selectedStates">
                        <mat-option *ngFor="let s of states" [value]="s">{{s}}</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
                <button mat-flat-button color="primary" (click)="openCreateSalesProposalNewCompanyDialog()">Angebot an Neukunden erstellen</button>
                <button mat-flat-button color="primary" (click)="openCreateSalesProposalExistingCompanyDialog()">Angebot an Bestandskunden erstellen</button>
                <loading-button
                        matTooltip="Aktualisieren"
                        buttonClass="mat-primary"
                        [loadingBool]="loading"
                        (clickEvent)="getSalesProposals()">
                    <i class="mdi mdi-refresh dark filter-color"></i>
                </loading-button>
            </div>
        </div>
    </div>

    <loading-spinner [loadingBool]="loading">
        <table mat-table [dataSource]="datasource" class="max-table" #table>

            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef>Typ</th>
                <td mat-cell *matCellDef="let element">
                    <span *ngIf="element.company.id === 0">Angebot an Neukunde</span>
                    <span *ngIf="element.company.id !== 0">Angebot an Bestandskunde</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef="let element">
                    {{element.product.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="state">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                    {{element.state}}
                </td>
            </ng-container>

            <ng-container matColumnDef="company">
                <th mat-header-cell  *matHeaderCellDef>Unternehmen</th>
                <td mat-cell *matCellDef="let element" style="max-width: 300px">
                    {{element.company.name}}
                </td>
            </ng-container>

            <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef>Bewerbungen</th>
                <td mat-cell *matCellDef="let element">
                    {{element.size}}
                </td>
            </ng-container>

            <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef> Original Preis</th>
                <td mat-cell *matCellDef="let element">
                    {{element.originalPrice}} €
                </td>
            </ng-container>

            <ng-container matColumnDef="discount">
                <th mat-header-cell *matHeaderCellDef>Rabatt</th>
                <td mat-cell *matCellDef="let element">
                    {{element.discount}} %
                </td>
            </ng-container>

            <ng-container matColumnDef="totalPrice">
                <th mat-header-cell *matHeaderCellDef>Preis ohne Steuer</th>
                <td mat-cell *matCellDef="let element">
                    {{element.totalPriceWithoutTax}} €
                </td>
            </ng-container>

            <ng-container matColumnDef="created">
                <th mat-header-cell *matHeaderCellDef>Erstellt am</th>
                <td mat-cell *matCellDef="let element">
                    {{element.createdAt | date:'dd.MM.yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="updated">
                <th mat-header-cell *matHeaderCellDef>Updated am</th>
                <td mat-cell *matCellDef="let element">
                    {{element.updatedAt | date:'dd.MM.yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="views">
                <th mat-header-cell *matHeaderCellDef>Ansichten</th>
                <td mat-cell *matCellDef="let element">
                    {{element.views }}
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell  *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.state == 'Open'" mat-stroked-button (click)="openConfirmationDialog(element)" color="primary">zurückziehen</button>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let element; columns: displayedColumns;"
                class="element-row">
            </tr>
        </table>

        <div *ngIf="datasource?.length == 0" class="nothing-found">Es wurde nichts gefunden</div>
    </loading-spinner>

    <mat-paginator [pageSize]="pageSize"
                   [pageSizeOptions]="pageSizeOptions"
                   [showFirstLastButtons]="true"
                   [length]="totalSize"
                   [hidePageSize]="false"
                   [pageIndex]="pageIndex"
                   (page)="handlePage($event)">
    </mat-paginator>
</div>
